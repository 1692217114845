/**
 * id: 游戏ID
 * name: 游戏名称
 * type: 游戏类型; chess(棋牌) fish(鱼机) slot(虎机)
 * describe: 游戏描述
 * system：兼容
 * language: 语言
 *    zh_tw(繁体中文) zh_cn(简体中文) en_us(英文) ko_kr(韓) th_th(泰) vi_vn(越) 葡(pt_pt) 西(es_es) 印度文(en_in) 日(ja_jp)
 * odds: 赔率
 * spec: {
 *  period: 时段波动
 *  rtp: 实时传输协议
 *  bigWin: 最大胜利
 *  fullSize: 全尺寸
 *  initLoad: 初始下载
 * }
 * time: 上线时间
 */

// 所有游戏配置
import i18n from '@/i18n/index'
export const gameData = [
  {
    id: 320, //超级王牌
    name: i18n.t('gameName320'),
    type: 'slot',
    describe: i18n.t('gameDescribe320'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁简英韩泰越葡西印日,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '1500',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '1500X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-06-29',
  },
  {
    id: 113,
    name: i18n.t('gameName113'),
    type: 'chess',
    describe: i18n.t('gameDescribe113'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '1.95',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '1.950X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 202,
    name: i18n.t('gameName202'),
    type: 'fish',
    describe: i18n.t('gameDescribe202'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '1000',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '1000X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 307,
    name: i18n.t('gameName307'),
    type: 'slot',
    describe: i18n.t('gameDescribe307'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '100',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '100X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 12,
    name: i18n.t('gameName12'),
    type: 'chess',
    describe: i18n.t('gameDescribe12'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '8.567',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '8.567X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 203,
    name: i18n.t('gameName203'),
    type: 'fish',
    describe: i18n.t('gameDescribe203'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '1372',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '1372X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 305,
    name: i18n.t('gameName305'),
    type: 'slot',
    describe: i18n.t('gameDescribe305'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '666',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '666X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 306,
    name: i18n.t('gameName306'),
    type: 'slot',
    describe: i18n.t('gameDescribe306'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '10000',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '10000X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 311, //罗马竞技场
    name: i18n.t('gameName311'),
    type: 'slot',
    describe: i18n.t('gameDescribe311'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '700',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '700X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 301,
    name: i18n.t('gameName301'),
    type: 'slot',
    // describe: i18n.t('gameDescribe301'),   已拼接至首页
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '888',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '888X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 302,
    name: i18n.t('gameName302'),
    type: 'slot',
    describe: i18n.t('gameDescribe302'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '3000',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '3000X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 303,
    name: i18n.t('gameName303'),
    type: 'slot',
    describe: i18n.t('gameDescribe303'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '3000',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '3000X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 304,
    name: i18n.t('gameName304'),
    type: 'slot',
    describe: i18n.t('gameDescribe304'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '3000',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '3000X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 308,
    name: i18n.t('gameName308'),
    type: 'slot',
    describe: i18n.t('gameDescribe308'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '100',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '100X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 309,
    name: i18n.t('gameName309'),
    type: 'slot',
    describe: i18n.t('gameDescribe309'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '3000',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '3000X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 310,
    name: i18n.t('gameName310'),
    type: 'slot',
    describe: i18n.t('gameDescribe310'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '2000',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '2000X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 323, //仙境探宝
    name: i18n.t('gameName323'),
    type: 'slot',
    describe: i18n.t('gameDescribe323'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '3000',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '3000X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 313, //龙龙龙
    name: i18n.t('gameName313'),
    type: 'slot',
    describe: i18n.t('gameDescribe313'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '888',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '888X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 324, //招财金猪
    name: i18n.t('gameName324'),
    type: 'slot',
    describe: i18n.t('gameDescribe324'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '88',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '88X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 201,
    name: i18n.t('gameName201'), //金蟾捕鱼
    type: 'fish',
    describe: i18n.t('gameDescribe201'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '288',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '288X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 204,
    name: i18n.t('gameName204'),
    type: 'fish',
    describe: i18n.t('gameDescribe204'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '999',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '999X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 205,
    name: i18n.t('gameName205'),
    type: 'fish',
    describe: i18n.t('gameDescribe205'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: [
      'zh_cn',
      'zh_tw',
      'en_us',
      'ko_kr',
      'th_th',
      'vi_vn',
      'pt_pt',
      'es_es',
      'en_in',
      'ja_jp',
    ],
    odds: '888',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '888X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 5,
    name: i18n.t('gameName5'), //红黑大战
    type: 'chess',
    describe: i18n.t('gameDescribe5'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '10.5',
    spec: {
      picture: '',
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '10.500X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 9,
    name: i18n.t('gameName9'),
    type: 'chess',
    describe: i18n.t('gameDescribe9'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '12',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '12.000X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 10,
    name: i18n.t('gameName10'),
    type: 'chess',
    describe: i18n.t('gameDescribe10'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '4.8',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '4.800X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 11,
    name: i18n.t('gameName11'),
    type: 'chess',
    describe: i18n.t('gameDescribe11'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '9',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '9.000X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 14,
    name: i18n.t('gameName14'),
    type: 'chess',
    describe: i18n.t('gameDescribe14'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '1.95',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '1.950X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 18,
    name: i18n.t('gameName18'),
    type: 'chess',
    describe: i18n.t('gameDescribe18'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '1.425',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '1.425X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 22,
    name: i18n.t('gameName22'),
    type: 'chess',
    describe: i18n.t('gameDescribe22'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '1.95',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '1.950X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 25,
    name: i18n.t('gameName25'),
    type: 'chess',
    describe: i18n.t('gameDescribe25'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '1.95',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '1.950X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 27,
    name: i18n.t('gameName27'),
    type: 'chess',
    describe: i18n.t('gameDescribe27'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '1.95',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '1.950X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 29,
    name: i18n.t('gameName29'),
    type: 'chess',
    describe: i18n.t('gameDescribe29'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '12',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '12.000X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 32,
    name: i18n.t('gameName32'),
    type: 'chess',
    describe: i18n.t('gameDescribe32'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '10.5',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '10.500X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 115,
    name: i18n.t('gameName115'),
    type: 'chess',
    describe: i18n.t('gameDescribe115'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '1.95',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '1.950X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 117,
    name: i18n.t('gameName117'),
    type: 'chess',
    describe: i18n.t('gameDescribe117'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '1.95',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '1.95X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
  {
    id: 126,
    name: i18n.t('gameName126'),
    type: 'chess',
    describe: i18n.t('gameDescribe126'),
    system: ['iOS', 'Android', 'Windows', 'MacOS', 'HTML5'],
    // 繁,簡,英,韓,泰,越,
    language: ['zh_tw', 'zh_cn', 'en_us', 'ko_kr', 'th_th', 'vi_vn'],
    odds: '1.95',
    spec: {
      period: i18n.t('inner7'),
      rtp: '96.92%',
      bigWin: '1.95X',
      fullSize: '9MB',
      initLoad: '9MB',
    },
    time: '2023-03-06',
  },
]

// 首页热门游戏
export const hotGameId = [306, 303, 320, 307, 311, 305]

// 游戏类型
export const gameType = {
  chess: i18n.t('navChess'),
  fish: i18n.t('navFish'),
  slot: i18n.t('navSlot'),
}

// 兼容系统
export const gameSystem = {
  iOS: 'iOS',
  Android: 'Android',
  Windows: 'Windows',
  MacOS: 'MacOS',
  HTML5: 'HTML5',
}

// DEMO链接地址
export const demoUrl = 'https://dayundemo.qaz15044.com/jump.html'
